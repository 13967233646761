<template>
  <v-container fluid>
    <v-row>
      <v-col sm="6" cols="4">
      <v-btn class="pink white--text"  @click="archiveBoardPacks()"><v-icon left>mdi-archive</v-icon> Archive Board Packs</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="6" cols="4">
        <v-text-field
          v-model="searchQuery"
          outlined
          clearable
          rounded
          dense
          label="Search Packs by Board Name"
          type="text"
        >
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              Filter for any Meeting by Typing Board name
            </v-tooltip>
          </template>
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-progress-circular
                v-if="loading"
                size="24"
                color="info"
                indeterminate
              ></v-progress-circular>
              <v-icon v-else>mdi-magnify</v-icon>
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <pack-card
      class="intro-y"
        v-for="pack in meetinglist"
        :key="pack"
        :packdata="pack"
      ></pack-card>

      <v-col sm="12" md="6" cols="5">
        <v-col class="d-flex" cols="12" sm="4"> Board Packs </v-col>
        <v-card
          max-width="100%"
          outlined
          v-for="pack in allBoardPacks"
          :key="pack.packUniqueId"
          style="margin: 3px"
          class="intro-y"
        >
          <v-list-item>
            <v-list-item-avatar tile size="50">
              <v-img src="@/assets/pdf.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-0">
                {{ pack.packName }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><small
                  >Created by: <strong>{{ pack.packCreator }}</strong></small
                >,
                <small
                  >Created On:
                  <strong>{{
                    pack.packCreateddate | formatDate
                  }}</strong></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-menu z-index="1" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="opened = !opened"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list dense>
                <v-list-item link to="">
                  <v-list-item-title
                    @click="previewPack(pack.packPath, pack.packUniqueId)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-eye-outline</v-icon
                    >
                    View Pack</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link to="">
                  <v-list-item-title
                    @click="startBoardPackAnnotation(pack.packUniqueId)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-edit-outline</v-icon
                    >
                    Make Annotations</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link to="">
                  <v-list-item-title @click="viewPackViewers(pack.packViewers)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-folder-eye-outline</v-icon
                    >
                    People Viewed this Pack</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card>

        <div class="text-center">
          <!-- <v-pagination
            v-model="page"
            :length="allBoardPacks.length"
            :total-visible="5"
            circle
          ></v-pagination> -->
        </div>
      </v-col>

      <v-col sm="12" md="6" cols="4">
        <v-col class="d-flex" cols="12" sm="12"> My Annotations </v-col>
        <v-card
          max-width="100%"
          outlined
          v-for="pack in annotatedBoardPacksList"
          :key="pack.packUniqueId"
          style="margin: 3px"
          class="intro-y"
        >
          <v-list-item>
            <v-list-item-avatar tile size="50">
              <v-img src="@/assets/pdf.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-0">
                {{ pack.packName }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><small
                  >Created by: <strong>{{ pack.packCreator }}</strong></small
                >,
                <small
                  >Created On:
                  <strong>{{
                    pack.packCreateddate | formatDate
                  }}</strong></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-menu z-index="1" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="opened = !opened"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list dense>
                <v-list-item link to="">
                  <v-list-item-title @click="openPack(pack.packPath)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-document-edit-outline</v-icon
                    >
                    View Annotations</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link to="" v-if="settings.canShareAnnotation">
                  <v-list-item-title
                    @click="startBoardPackAnnotationSharing(pack.packUniqueId)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-share</v-icon
                    >
                    Share Annotations</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card>

        <div class="text-center">
          <!-- <v-pagination
            v-model="page"
            :length="allBoardPacks.length"
            :total-visible="5"
            circle
          ></v-pagination> -->
        </div>
      </v-col>

      <v-col sm="12" md="6" cols="3">
        <v-row>
          <v-col cols="6" sm="6"> Shared Annotations</v-col>
          <v-col cols="6" sm="6">
            <v-select
              @change="filterSharedAnnotations($event)"
              :items="[
                { user: 'From Other Members', value: 'other' },
                { user: 'From Me', value: 'me' },
              ]"
              item-text="user"
              item-value="value"
              label="Filter"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-card
          max-width="100%"
          outlined
          v-for="pack in sharedAnnotatedBoardPacksList"
          :key="pack.packUniqueId"
          style="margin: 3px"
          class="intro-y"
        >
          <div class="px-3 pt-3" style="background-color: rgba(0,128,0,0.05);">
            <div class="flex flex-column pb-2">
              <span class="font-medium text-gray-100">Comment:</span>
              <span class="text-gray-100 text-sm">{{ pack.packComments }}</span>
            </div>
          </div>
            <v-divider style="border-color: rgba(0,128,0,0.3)"></v-divider>

          <v-list-item>
            <v-list-item-avatar tile size="50">
              <v-img src="@/assets/pdf.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-0">
                {{ pack.packName }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><small
                  >Created by: <strong>{{ pack.packCreator }}</strong></small
                >,
                <small
                  >Created On:
                  <strong>{{
                    pack.packCreateddate | formatDate
                  }}</strong></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-menu z-index="1" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="opened = !opened"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list dense>
                <v-list-item link to="">
                  <v-list-item-title @click="openPack(pack.packPath)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-eye-outline</v-icon
                    >
                    View Pack</v-list-item-title
                  >
                </v-list-item>
                <!--                <v-list-item link to="" v-if="settings.canShareAnnotation">-->
                <!--                  <v-list-item-title-->
                <!--                    @click="startBoardPackAnnotationSharing(pack.packUniqueId)"-->
                <!--                    ><v-icon style="height: 16px; font-size: 16px"-->
                <!--                      >mdi-share</v-icon-->
                <!--                    >-->
                <!--                    Share Annotations</v-list-item-title-->
                <!--                  >-->
                <!--                </v-list-item>-->
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card>
        <div class="text-center">
          <!-- <v-pagination
            v-model="page"
            :length="10"
            :total-visible="4"
            circle
          ></v-pagination> -->
        </div>
      </v-col>
    </v-row>

    <ShareAnnotationDialog
      :OpenSharedAnnotationDialog="OpenSharedAnnotationDialog"
      :annotation="annotation"
      @CloseSharedAnnotationDialog="OpenSharedAnnotationDialog = false"
    />

    <DocumentViewer
      v-if="OpenfileDialog == true"
      :docValue="docValue"
      :OpenfileDialog="OpenfileDialog"
      :title="title"
      :packUniqueId="packUniqueId"
      @CloseDocumentViwer="OpenfileDialog = false"
    />

    <PackViewersDialog
      :packViewersList="packViewersList"
      :isOpenPackViewersDialog="isOpenPackViewersDialog"
      @closePackViewersDialog="
        packViewersList = [];
        isOpenPackViewersDialog = false;
      "
    />
  </v-container>
</template>

<script>
import PackCard from "./PackCard.vue";
import ShareAnnotationDialog from "./ShareAnnotationDialog.vue";
import DocumentViewer from "../eboard-documents/DocumentViewer.vue";
import PackViewersDialog from "./PackViewersDialog.vue";
import swal from 'sweetalert2'

export default {
  components: {
    PackCard,
    ShareAnnotationDialog,
    DocumentViewer,
    PackViewersDialog,
  },

  data() {
    return {
      packViewersList: [],
      isOpenPackViewersDialog: false,
      document: "",
      packUniqueId: "",
      docValue: null,
      title: null,
      OpenfileDialog: false,
      OpenAnnotationDialog: false,
      annotation: null,
      OpenSharedAnnotationDialog: false,
      searchQuery: "All",
      page: 1,
      loading: false,

      meetinglist: [
        {
          MeetingName: "Board Packs",
          packRedingStatus: "warning",
        },
        {
          MeetingName: "My Annotations",
          packRedingStatus: "info",
        },
        {
          MeetingName: "Shared Annotations",
          packRedingStatus: "success",
        },
      ],
    };
  },

  computed: {
    settings() {
      return this.$store.state.SettingsStore.boardSettings;
    },
    allBoardPacks() {
      var packs=this.$store.state.BoardPacksStore.boardPacksList;
      if (this.searchQuery=="All") {
        return packs
      }else{
        return packs.filter(pack => {
          return pack.packName.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    },
    annotatedBoardPacksList() {
      var packs=this.$store.state.BoardPacksStore.annotatedBoardPacksList;
      if (this.searchQuery=="All") {
        return packs
      }else{
        return packs.filter(pack => {
          return pack.packName.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    },
    sharedAnnotatedBoardPacksList() {
      return this.$store.state.BoardPacksStore.sharedAnnotatedBoardPacksList;
    },
  },

  methods: {
    viewPackViewers(packViewersList) {
      this.isOpenPackViewersDialog = true;
      this.packViewersList = packViewersList;
    },

    async previewPack(docValue, packUniqueId) {
      this.docValue = docValue;
      await this.$store.dispatch("createBoardMeetingPackViewer", this.packUniqueId);
      this.packUniqueId = packUniqueId;
      this.OpenfileDialog = true;
    },

    async filterSharedAnnotations(event) {
      await this.$store.dispatch("loadSharedAnnotatedBoardPacks", event);
    },

    openPack(document) {
      this.$store.dispatch("setSelectedPack", "/" + document);
      this.$router.push("/eboard_open_packs");
    },

    async startBoardPackAnnotationSharing(packUniqueId) {
      this.annotation = packUniqueId;
      this.OpenSharedAnnotationDialog = true;
    },

    async archiveBoardPacks() {
      await swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'This will archive Packs, Personal Annotation and Shared Annotation',
          showCancelButton: true,
          confirmButtonText: 'Yes, Archive all !',
          cancelButtonText: 'No, cancel!',
          confirmButtonColor: 'red',
      }).then((result) => {
        if (result.isConfirmed) {
            this.$store.dispatch("showLoadingSpinner", true);
            this.$store.dispatch("archiveBoardMeetingPacks");
            this.$store.dispatch("loadAllBoardPacks");
            this.$store.dispatch("loadAnnotatedBoardPacks");
            this.$store.dispatch("loadSharedAnnotatedBoardPacks");
            this.$store.dispatch("showLoadingSpinner", false);
        }
      });
    },
    async startBoardPackAnnotation(packUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("startBoardPackAnnotation", packUniqueId);
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("SettingsStore/getBoardMeetingSettings",{root:true});
    await this.$store.dispatch("loadAllBoardPacks");
    await this.$store.dispatch("loadAnnotatedBoardPacks");
    await this.$store.dispatch("loadSharedAnnotatedBoardPacks");
    await this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style>
.scroll {
  overflow-y: auto;
}
</style>


