<template>
  <div>
    <h4 style="margin-left: 2em; margin-top: 2em">Meeting Quorum</h4>

    <v-row>
      <v-col class="px-4">
        <v-slider
          v-model="quorumValue"
          :label="quorumValue + '%'"
          :thumb-color="ex3.color"
          thumb-label="always"
          @change="updateBoardQuorum($event)"
          style="margin-left: 2em; margin-right: 2em; margin-top: 2em"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="3" md="3" sm="6" xs="6" v-if="onlyfornextTime">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('share_board_pack_via_email',$event)" v-model="boardSetting().shareBoardPackViaEmail" inset color="indigo">
            <template v-slot:label>
              Share BoardPack via email
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6" v-if="onlyfornextTime">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('share_minutes_via_email',$event)" v-model="boardSetting().shareMinutesViaEmail" inset color="orange darken-4">
            <template v-slot:label>
              Share Minutes via email
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6" v-if="onlyfornextTime">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('share_agenda_via_email',$event)"  v-model="boardSetting().shareAgendaViaEmail" inset color="light-blue darken-4">
            <template v-slot:label>
              Share Agenda via email
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('hide_conducted_meetings',$event)"  v-model="boardSetting().hideConductedMeetings" inset color="yellow darken-4">
            <template v-slot:label>
              Hide conducted meeting
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch
             v-model="boardSetting().canShareAnnotation"
            inset
            color="blue-grey darken-4"
            @change="updateBoardSettingsValues('can_share_annotation',$event)"
          >
            <template v-slot:label>
              Allow Annotations Sharing
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('can_delete_annotation_after_confirm_minutes',$event)" v-model="boardSetting().canDeleteAnnotationAfterConfirmMinutes" inset color="blue darken-4">
            <template v-slot:label>
              Delete Annotations after Meeting
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('show_agenda_purpose',$event)" v-model="boardSetting().showAgendaPurpose" inset color="red darken-4">
            <template v-slot:label>
              Show Agenda Purpose
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('show_agenda_presenter',$event)" v-model="boardSetting().showAgendaPresenter" inset color="blue darken-4">
            <template v-slot:label>
              Show Agenda Presenter
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('show_agenda_duration',$event)" v-model="boardSetting().showAgendaDuration" inset color="green darken-4">
            <template v-slot:label>
              Show Agenda Duration
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('matter_arising_responsible_personel',$event)" v-model="boardSetting().matterArisingResponsiblePersonel" inset color="orange darken-4">
            <template v-slot:label>
              Matters Arising Responsible Personnel
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('show_conflicted_organization',$event)" v-model="boardSetting().showConflictedOrganization" inset color="orange darken-4">
            <template v-slot:label>
              Conflict of Interest Declaration is Mandatory
            </template>
          </v-switch>
        </v-sheet>
      </v-col>

      <v-col lg="3" xl="3" md="3" sm="6" xs="6">
        <v-sheet class="pa-5 ml-4">
          <v-switch @change="updateBoardSettingsValues('declaration_is_mandatory',$event)" v-model="boardSetting().declarationIsMandatory" inset color="orange darken-4">
            <template v-slot:label>
              Show Organization in Conflict of Interest
            </template>
          </v-switch>
        </v-sheet>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col  lg="5" xl="5" md="5" sm="6" xs="6" class="mb-2 mt-2">
        <ButtonConfiguration/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ButtonConfiguration from './ButtonConfiguration.vue'
export default {
  components:{ButtonConfiguration},
  data() {
    return {
      ex3: { label: "0%", color: "indigo" },
      switchMe: false,
      switchMe1: false,
      switchMe3: false,
      switchMe2: false,
      switchMe4: false,
      sharingAnnotationSwitch: false,
      deleteAnnotationSwitch: false,
    };
  },

  computed: {
    settings() {
      return this.$store.state.SettingsStore.boardSettings;
    },
    quorumValue: {
      get() {
        return this.$store.state.SettingsStore.boardSettings.settingQuorumPercentage;
      },
      set(newValue) {
        return newValue;
      },
    },
    sharingAnnotationSetting() {
      return this.$store.state.SettingsStore.boardSettings.canShareAnnotation;
    },
  },

  watch: {
    sharingAnnotationSetting() {
      this.sharingAnnotationSwitch = this.sharingAnnotationSetting;
    },
  },

  methods: {
    async updateBoardQuorum(quorumValue) {
      this.quorumValue = quorumValue;
      await this.$store.dispatch("SettingsStore/updateMeetingQuorum", quorumValue);
    },

    async updateBoardSettingsValues(editedPart, currentValue) {
      let Setting = {
        settingType: editedPart,
        settingsValue: currentValue,
        settingIsColor: false,
        settingsColorValue: ""
      }
      await this.$store.dispatch('SettingsStore/updateSystemSettings', Setting);
      await this.$store.dispatch('SettingsStore/loadSMSConfigurations', {root: true})
    },
  },
};
</script>

<style>
</style>
