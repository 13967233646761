<template>
  <div
    class="relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
  >
    <div class="intro-x relative flex items-center mb-1">
      <div class="box px-5 py-3 ml-4 flex-1">
        <div class="flex items-center">
          <div style="font-size: 1.1rem" class="font-medium">
            {{ resolution.resolutionTitle }}
          </div>
          <div class="text-xs text-slate-500 ml-auto">
            <v-chip
              small
              v-if="resolution.resolutionIsPublished"
              class="border secondary border-dotted border-slate-400"
              ><v-icon small color="white">mdi-calendar-month</v-icon>
              <strong>To be close at: </strong>
              {{ resolution.resolutionEndDate | formatDate }}
              {{ resolution.resolutionEndTime }}</v-chip
            >
          </div>
        </div>
        <hr class="mt-2" />
        <div class="text-slate-500 mt-1">
          <span v-html="resolution.resolutionDescriptions"></span>
        </div>
        <hr class="mt-2" />
        <div class="flex items-center">
          <v-chip small outlined
            ><strong>Organized by: </strong>
            <span> {{ resolution.resolutionOrganiser }}</span></v-chip
          >
          <div class="text-xs text-slate-500 ml-auto">
            <v-btn-toggle rounded>
              <v-btn
                v-if="!resolution.resolutionIsPublished && hasAccess('can_add_resolution')"
                @click="OpenEditDialog=true"
                text
                small
                outlined
                color="primary"
              >
                <v-icon small color="primary">mdi-pencil-outline</v-icon> Edit
              </v-btn>
              <v-btn
                v-if="!resolution.resolutionIsPublished && hasAccess('can_delete_resolution')"
                @click="deleteCircularResolution(resolution.resolutionUniqueId)"
                text
                small
                outlined
                color="pink"
              >
                <v-icon small color="pink">mdi-delete</v-icon> Delete
              </v-btn>
              <v-btn
                v-if="!resolution.resolutionIsPublished && hasAccess('can_add_resolution')"
                @click="
                  publishCircularResolution(resolution.resolutionUniqueId, true)
                "
                text
                small
                outlined
                color="success"
              >
                <v-icon small color="success">mdi-publish</v-icon> Publish
              </v-btn>
              <v-btn
                v-if="resolution.resolutionIsPublished && hasAccess('can_add_resolution') && resolution.resolutionIsActive"
                @click="
                  publishCircularResolution(
                    resolution.resolutionUniqueId,
                    false
                  )
                "
                text
                small
                outlined
                color="orange"
              >
                <v-icon small color="orange">mdi-download</v-icon> Un-Publish
              </v-btn>
            </v-btn-toggle>

            <v-badge
            v-if="resolution.resolutionIsPublished"
              :content="resolution.resolutionComment || '0'"
              color="primary"
              overlap
              class="mr-2 mt-4"
            >
              <v-btn
                @click="openResolutionComments(resolution.resolutionUniqueId)"
                text
                class="ma-1 rounded-full"
                small
                outlined
                ><v-icon small color="grey">mdi-comment</v-icon> Comments</v-btn
              ></v-badge
            >
            <v-badge
              :content="resolution.resolutionDocuments.length || '0'"
              color="pink"
              overlap
              class="mr-2 mt-4"
            >
              <v-btn
                @click="openSupportingDocument(resolution.resolutionDocuments)"
                text
                class="ma-1 rounded-full"
                small
                outlined
                ><v-icon small color="grey">mdi-paperclip</v-icon>
                Documents</v-btn
              ></v-badge
            >
            <v-badge
            v-if="resolution.resolutionIsPublished && hasAccess('can_finalize_resolution')"
              :content="resolution.voteResults.voteVoters"
              color="success"
              overlap
              class="mr-2 mt-4"
            >
              <v-btn
                @click="
                  openVotingResultsPage(
                    resolution,
                    resolution.primaryKey,
                    resolution.voteResults,
                    resolution.resolutionTitle
                  )
                "
                outlined
                text
                class="ma-1 rounded-full"
                small>
                <v-icon color="grey">mdi-vote</v-icon>
                View Results
              </v-btn>
            </v-badge>

            <v-btn
                v-if="resolution.resolutionIsPublished && !resolution.resolutionIsActive"
                @click="exportCircularResolution(resolution.resolutionUniqueId)"
                outlined
                text
                class="ma-1 rounded-full"
                small>
              <v-icon color="grey">mdi-vote</v-icon>
              Export Results
            </v-btn>

            <v-btn
                v-if="hasAccess('can_add_resolution')"
              @click="OpenReminderDialog = true"
              text
              class="ma-1 bg-indigo"
              small
              fab
            >
              <v-icon>mdi-alarm-plus</v-icon>
            </v-btn>
<!--            <v-btn-->
<!--              @click="openReminderDialogCard(resolution)"-->
<!--              text-->
<!--              class="ma-1 bg-indigo"-->
<!--              small-->
<!--              fab-->
<!--            >-->
<!--              <v-icon>mdi-printer</v-icon>-->
<!--            </v-btn>-->
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="voteDialog" persistent width="80%">
      <ResolutionVoteResults
        :resolution="resolution"
        :motion="motion"
        :primaryKey="primaryKey"
        :voteData="voteData"
        @OpenVoteDialog="openResolutionAttachment"
        @closeVoteDialog="voteDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="filePreviewDialog" persistent width="70%">
      <ResolutionDocuments
        :documentList="suppotingDocs"
        :resolution="resolution"
        @openFileDialog="openResolutionAttachment"
        @closefileDialog="
          filePreviewDialog = false;
          suppotingDocs = [];
        "
      />
    </v-dialog>

    <ResolutionReminder
      :resolutionDueDate="resolution.resolutionEndDate"
      :OpenReminderDialog="OpenReminderDialog"
      @CloseReminderDialog="OpenReminderDialog = false"
    />
    <DocumentViwer
        v-if="openFileDialog"
        :openfile-dialog="openFileDialog"
        :docValue="docValue"
        :title="title || `View Attachment`"
        @CloseDocumentViwer="openFileDialog = false"
    />
    <v-dialog v-model="commentsPreviewDialog" persistent width="80%">
      <ResolutionComments
        :resolution="resolution"
        @closeCommentsDialog="commentsPreviewDialog = false;"
      />
    </v-dialog>


    <EditCircularResolution
      :OpenEditDialog="OpenEditDialog"
      :resolution="resolution"
      @CloseNewCircularDialog="OpenEditDialog = false"
    />
  </div>
</template>

<script>
import ResolutionVoteResults from "./ResolutionVoteResults.vue";
import ResolutionDocuments from "./ResolutionDocuments.vue";
import ResolutionReminder from "./ResolutionReminder.vue";
import ResolutionComments from "./ResolutionComments.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import EditCircularResolution from "./EditCircularResolution.vue";
import {apolloClient} from "@/vue-apollo";
import exportResolutionResults from "@/graphql/eboard-flyingminutes-graphql/exportResolutionResults.gql";
export default {
  props: ["nonBoardMembers", "resolution"],
  components: {
    ResolutionVoteResults,
    ResolutionDocuments,
    ResolutionReminder,
    DocumentViwer,
    ResolutionComments,
    EditCircularResolution
  },
  data() {
    return {
      path: "",
      title: "",
      voteData: null,
      motion: null,
      docValue: null,
      openFileDialog: false,
      voteDialog: false,
      OpenReminderDialog: false,
      filePreviewDialog: false,
      commentsPreviewDialog: false,
      suppotingDocs: [],
      primaryKey: "",
      OpenEditDialog:false,
    };
  },
  computed: {
    exportedCircularResolutionAttachment() {
      return this.$store.getters.getExportedCircularResolution
    }
  },
  methods: {
    async exportCircularResolution(resolutionUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: exportResolutionResults,
        variables: {
          resolutionUniqueId
        }
      }).then((response) => {
        let result = response.data.exportResolutionResults
        this.docValue = `/${result.data?.attachmentPath}`
        this.openFileDialog = true
        this.$store.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await this.$store.dispatch("showLoadingSpinner", false);
      })

    },
    openSupportingDocument(docs) {
      this.suppotingDocs = [];
      this.suppotingDocs = docs;
      this.filePreviewDialog = true;
    },
    openVotingResultsPage(resolution, primaryKey, votes, motion) {
      this.resolution = resolution;
      this.motion = motion;
      this.voteData = votes;
      this.primaryKey = primaryKey;
      this.voteDialog = true;
    },

    openResolutionAttachment(docname, path) {
      this.title = docname;
      this.docValue = path;
      this.openFileDialog = true;
    },

    async publishCircularResolution(resolutionUnqueId, publishing) {
      this.$store.dispatch("showLoadingSpinner", true);
      this.$store.dispatch("publishDraftResolution", {
        resolutionUnqueId: resolutionUnqueId,
        publishing: publishing,
      });
      this.$store.dispatch("showLoadingSpinner", false);
    },

    async deleteCircularResolution(resolutionUnqueId) {
      this.$store.dispatch("showLoadingSpinner", true);
      this.$store.dispatch("deleteDraftResolution", {
        resolutionUnqueId: resolutionUnqueId,
      });
      this.$store.dispatch("showLoadingSpinner", false);
    },

    async openResolutionComments(resolutionUnqueId) {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadResolutionsComments", {resolutionUnqueId: resolutionUnqueId,});
      this.commentsPreviewDialog=true
      this.$store.dispatch("showLoadingSpinner", false);
    }
  },
};
</script>
