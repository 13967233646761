<template>
  <v-row justify="center">
    <v-dialog
        v-model="showContextMenu"
        max-width="1000px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-group-outline</v-icon> Add New Meeting</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="meetingObj.meetingTitle"
                    :counter="100"
                    label="Title *"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-select
                    v-model="meetingObj.meetingCategory"
                    :items="meetingCategories"
                    item-text='categoryTittle'
                    item-value='id'
                    label="Meeting Category *"
                    prepend-icon="mdi-shape-outline"
                    required
                >
                </v-select>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
                  sm="6"
              >
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingObj.meetingDate"
                        label="Date *"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(meetingDate)"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="meetingObj.meetingDate"
                      :min="date"
                      no-title
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
                  sm="6"
              >
                <v-dialog
                    ref="dialog1"
                    v-model="modal1"
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingObj.meetingStartTime"
                        label="Start Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker  v-if="modal1" v-model="meetingObj.meetingStartTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
                  sm="6"
              >
                <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingObj.meetingEndTime"
                        label="End Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="modal2"
                      v-model="meetingObj.meetingEndTime"
                      :min="meetingObj.meetingStartTime"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col
                  cols="12"
                  sm="12"
              >
                <v-select
                    v-model="meetingObj.meetingLocation"
                    :hint="timezone"
                    :items="locations"
                    item-value="id"
                    item-text='location'
                    label="Location *"
                    persistent-hint
                    prepend-icon="mdi-map-marker"
                    required
                    return-object
                    @change="getSelectedTimeZone"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip small text-color="">{{ item.location }}</v-chip>
                  </template>
                </v-select>
              </v-col>

            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              small
              outlined
              @click="closeNewMeetingDialog"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>

          <v-btn
              :disabled="loading"
              :loading="loading"
              class="white--text"
              color="blue darken-1"
              outlined
              small
              @click="createNewManagementMeeting"
          >
            <v-icon>mdi-floppy</v-icon>
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>

export default {
  data: () => ({
    loading: false,
    timezone: null,
    defaultSelected: '',
    time: null,
    menu: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingDate: '',
    startTime: '',
    meetingCategory: '',
    endTime: '',
    meetingTittle: '',
    createdMeeting: '',
    meetingObj: {
      meetingManagement: null,
      meetingTitle: null,
      meetingDate: null,
      meetingStartTime: null,
      meetingEndTime: null,
      meetingLocation: null,
      meetingCategory: null,
    }
  }),

  props: ["showContextMenu"],

  watch: {
    showContextMenu(value) {
      if (value)
        this.loadMeetingCategoriesAndLocations();
    }
  },

  computed: {
    meetingCategories() {
      let temp = [];
      let data = [];
      temp = this.$store.state.ManagementCategoriesStore.managementMeetingCategories;

      temp.forEach(item => {
        data.push({
          id: item.key,
          categoryTittle: item.title,
        })
      });

      return data;
    },

    locations() {
      return this.$store.state.SettingsStore.boardLocations
    }
  },

  methods: {
    closeNewMeetingDialog() {
      this.meetingObj = {
        meetingManagement: null,
        meetingTitle: null,
        meetingDate: null,
        meetingStartTime: null,
        meetingEndTime: null,
        meetingLocation: null,
        meetingCategory: null,
      };
      this.timezone = null;
      this.$emit("closeNewMeetingDialog");
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getSelectedTimeZone() {
      this.timezone = this.meetingObj.meetingLocation.locationTimezone;
    },

    async createNewManagementMeeting() {
      this.meetingObj.meetingLocation = this.meetingObj.meetingLocation.id;
      this.meetingObj.meetingManagement = localStorage.getItem('my-managementUniqueId');
      await this.$store.dispatch('createManagementMeeting', this.meetingObj);
      this.closeNewMeetingDialog();
    },

    async loadMeetingCategoriesAndLocations() {
      await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations",{root:true});
      await this.$store.dispatch("loadManagementMeetingCategories");
    }
  },
}
</script>
