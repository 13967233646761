var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-simple-table',{staticStyle:{"width":"100%"}},[[(!_vm.selectedAgenda)?_c('v-overlay',{attrs:{"absolute":_vm.absolute,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"10%"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"80%"}})])]),_c('tbody',[(_vm.boardSetting().showAgendaPurpose)?_c('tr',[_c('td',[_c('strong',[_vm._v("Purpose :")])]),_c('td',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.selectedAgenda.agendaPurpose))]),(!_vm.hasPublishedAgenda)?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","offset-y":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,1459556804)},[_c('v-card',{attrs:{"width":"700"}},[_c('v-card-text',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAgenda.agendaPurpose),expression:"selectedAgenda.agendaPurpose"}],staticClass:"mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",staticStyle:{"border":"1px solid black"},attrs:{"type":"text","placeholder":"Agenda Purpose","required":""},domProps:{"value":(_vm.selectedAgenda.agendaPurpose)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedAgenda, "agendaPurpose", $event.target.value)}}}),_c('v-btn',{staticClass:"mr-3 mb-1 ml-4 mt-5 indigo--text",attrs:{"small":"","outlined":"","icon":""},on:{"click":function($event){_vm.onPurposeInput(_vm.selectedAgenda, Date.now())}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)],1):_vm._e()],1)]):_vm._e(),(_vm.boardSetting().showAgendaPresenter)?_c('tr',[_c('td',[_c('strong',[_vm._v("Presenter :")])]),(_vm.hasAccess('can_schedule_meeting') && !_vm.hasPublishedAgenda)?_c('td',[_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"activator","color":"indigo","small":"","text":""},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(_vm._s(!_vm.presenter ? "No Presenter" : _vm.presenter)+" ")])]}}],null,false,1163159574)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.managementCommitteeMembers),function(member){return _c('v-list-item',{key:member.username,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.changePresenter(_vm.selectedAgenda, member)}}},[_vm._v(" "+_vm._s(member.firstName)+" "+_vm._s(member.lastName)+" ")])],1)}),1)],1)],1):_c('td',[_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"activator","color":"indigo","small":"","text":""},slot:"activator"},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(_vm._s(_vm.presenter)+" ")])],1)]):_vm._e(),(_vm.boardSetting().showAgendaDuration)?_c('tr',[_c('td',[_c('strong',[_vm._v("Duration :")])]),_c('td',{staticClass:"Editable"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.selectedAgenda.agendaDuration))]),_vm._v(" Min "),(_vm.hasAccess('can_schedule_meeting') && !_vm.hasPublishedAgenda)?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","offset-y":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,1459556804)},[_c('v-card',{attrs:{"width":"700"}},[_c('v-card-text',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAgenda.agendaDuration),expression:"selectedAgenda.agendaDuration"}],staticClass:"mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",staticStyle:{"border":"1px solid black"},attrs:{"type":"number","placeholder":"Agenda Duration","required":""},domProps:{"value":(_vm.selectedAgenda.agendaDuration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedAgenda, "agendaDuration", $event.target.value)}}}),_c('v-btn',{staticClass:"mr-3 mb-1 ml-4 mt-5 indigo--text",attrs:{"small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.onPurposeInput(_vm.selectedAgenda)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)],1):_vm._e()],1)]):_vm._e()])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }