import { apolloClient } from "@/vue-apollo";
import createResolutions from "../../graphql/eboard-flyingminutes-graphql/createResolutions.gql";
import updateResolution from "../../graphql/eboard-flyingminutes-graphql/updateResolution.gql";
import boardResolutions from "../../graphql/eboard-flyingminutes-graphql/boardResolutions.gql";
import publishDraftCircularResolution from "../../graphql/eboard-flyingminutes-graphql/publishDraftCircularResolution.gql";
import deleteDraftCircularResolution from "../../graphql/eboard-flyingminutes-graphql/deleteDraftCircularResolution.gql";
import circularResolutionVoteResult from "../../graphql/eboard-flyingminutes-graphql/circularResolutionVoteResult.graphql";
import myResolutionNotifications from "../../graphql/eboard-flyingminutes-graphql/myResolutionNotifications.gql";
import updateResolutionNotifications from "../../graphql/eboard-flyingminutes-graphql/updateResolutionNotifications.gql";
import resolutionVottingDetails from "../../graphql/eboard-flyingminutes-graphql/resolutionVottingDetails.gql";
import resolutionComments from "../../graphql/eboard-flyingminutes-graphql/resolutionComments.gql";
import addResolutionComments from "../../graphql/eboard-flyingminutes-graphql/addResolutionComments.gql";
import exportResolutionResults from "../../graphql/eboard-flyingminutes-graphql/exportResolutionResults.gql";
import addResolutionSupportingAttachments from "../../graphql/eboard-flyingminutes-graphql/addResolutionSupportingAttachments.gql";
import swal from "sweetalert2";
import uploadFile from "../../graphql/eboard-documents-graphql/uploadFile.gql";




export default {
  state: {
    boardResolutions: [],
    circularResolutionSupportingDocument: [],
    progressReportDocs: [],
    resolutionNotifications: [],
    resolutionVotingDetails: {},
    resolutionComments: [],
      exportedCircularResolution: {}
  },

  getters: {
    getResolutionsNotifications: (state) => state.resolutionNotifications,
    getResolutionsVotingDetails: (state) => state.resolutionVotingDetails,
    getResolutionComments: (state) => state.resolutionComments,
    getExportedCircularResolution: (state) => state.exportedCircularResolution,


    publishedCircularResolution: (state) => {
        return state.boardResolutions.filter(
            (resolution) =>
              resolution.resolutionIsPublished == true &&
              resolution.resolutionIsActive == true
          );
    },
    unPublishedCircularResolution: (state) => {
        return state.boardResolutions.filter(
            (resolution) => resolution.resolutionIsPublished == false
          );
    },
    closedCircularResolution: (state) => {
        return state.boardResolutions.filter(
            (resolution) =>
              resolution.resolutionIsPublished == true &&
              resolution.resolutionIsActive == false
          );
    },
  },

  mutations: {
    clearCircularResolutions(state){
      state.boardResolutions = []
    },
    addBoardResolutions(state, resolutionObjects) {
      state.boardResolutions = resolutionObjects;
    },
    clearCircularResolutionSuppotingDocuments(state) {
      state.circularResolutionSupportingDocument = [];
    },
    addCircularResolutionSuppotingDocuments(state, SupportingDocument) {
      state.circularResolutionSupportingDocument.push(SupportingDocument);
    },
    addProgressReportDocs(state, SupportingDocument) {
      state.progressReportDocs.push(SupportingDocument);
    },

    addResolutionNotifications(state, notifications) {
      state.resolutionNotifications = notifications;
    },

      setExportedCircularResolution(state, decision) {
          state.exportedCircularResolution = decision
      },
      cleanExportedCircularResolution(state) {
          state.exportedCircularResolution = {}
      },

    addResolutionVottingDetails(state, details) {
      state.resolutionVotingDetails = details;
    },
    updateResolutionPublicationStatus(state, data) {
        var foundresolution = state.boardResolutions.find(
          (resolution) => {
            return resolution.resolutionUniqueId == data.resolutionUnqueId;
          }
        );
        foundresolution.resolutionIsPublished = data.publishing;
    },
    deleteResolutionPublicationStatus(state, data) {
        state.boardResolutions.splice(state.boardResolutions.findIndex(resolution => resolution.resolutionUniqueId == data.resolutionUnqueId) , 1)
    },

    addResolutionComments(state, comments) {
      state.resolutionComments = comments;
    },

    appendResolutionComments(state, comments) {
      state.resolutionComments.push(comments);
    },
    appendResolutionDocuments(state, docData) {
      const index = state.boardResolutions.findIndex(resolution => resolution.resolutionUniqueId === docData.key);
      if (index !== -1) {
        state.boardResolutions[index].resolutionDocuments.push(docData.doc)
      }
    }

  },

  actions: {
    async circularResolutionSubmitFile(context, sendData) {
      await context.dispatch("showLoadingSpinner", true);
      var SavedFile = null;
      await apolloClient
        .mutate({
          mutation: uploadFile,
          loading: this.value,
          variables: {
            file: sendData.file,
          },
          update: (store, { data: { uploadFile } }) => {
            SavedFile = {
              fileName: uploadFile.fileName,
              fullFileName: uploadFile.fullFileName,
              fileSource: "Uploaded",
            };
            if (sendData.type === "directiveReport") {
              context.commit("addProgressReportDocs", SavedFile);
            } else {
              context.commit(
                "addCircularResolutionSuppotingDocuments",
                SavedFile
              );
            }
          },
        })
        .then((response) => {
          context.dispatch("showLoadingSpinner", false);
          console.log(response);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async loadBoardResolutions(context,data) {
      context.commit('clearCircularResolutions');
      context.commit("clearCircularResolutionSuppotingDocuments");
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: boardResolutions,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
            startDate: data?.startDate,
            endDate: data?.endDate,
          },
        })
        .then((response) => {
          context.commit("addBoardResolutions", response.data.boardResolutions);
        })
        .catch(async (error) => {
          console.log(error);
        });
    },

      // clearExportedCircularResolution(context) {
      //   context.dispatch("cleanExportedCircularResolution");
      // },

      async loadExportedCircularResolution(context,resolutionUniqueId) {
          await context.dispatch("showLoadingSpinner", true);
          await context.commit("cleanExportedCircularResolution");
          apolloClient.query({
              fetchPolicy: 'no-cache',
              query: exportResolutionResults,
              variables: {
                  resolutionUniqueId
              }
          }).then((response) => {
              let result = response.data.exportResolutionResults
              context.commit('setExportedCircularResolution', result.data)
              context.dispatch("showLoadingSpinner", false);
          }).catch(async (error) => {
              await context.dispatch("showLoadingSpinner", false);
              console.error(error);
          })
      },

    async loadMyResolutionNotifications(context) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: myResolutionNotifications,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
          },
        })
        .then((response) => {
          context.commit(
            "addResolutionNotifications",
            response.data.myResolutionNotifications
          );
          context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async loadResolutionVottingDetails(context, resolutionUniqueId) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: resolutionVottingDetails,
          variables: {
            resolutionUniqueId: resolutionUniqueId,
          },
        })
        .then((response) => {
          context.commit(
            "addResolutionVottingDetails",
            response.data.resolutionVottingDetails
          );
          context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async updateMyResolutionNotifications(context) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient
        .mutate({
          mutation: updateResolutionNotifications,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
          },
        })
        .then(() => {
          context.commit("addResolutionNotifications", []);
          context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async addBoardResolutions(context, resolution) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient
        .mutate({
          mutation: createResolutions,
          variables: {
            resolution: resolution,
          },
        })
        .then(() => {
          context.commit("addResolution", resolution);
          context.commit("clearCircularResolutionSuppotingDocuments");
          context.commit('clearCircularResolutions');
          context.dispatch('loadBoardResolutions')
          context.dispatch("showLoadingSpinner", false);
          swal.fire({
            toast: true,
            icon: "success",
            title: "Circular Resolution created successfully",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async updateBoardResolutions(context, resolution) {
      apolloClient
        .mutate({
          mutation: updateResolution,
          variables: {
            resolution: resolution,
          },
        })
        .then(() => {
          context.commit("addResolution", resolution);

        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async circularResolutionVoteResult(context, resolution) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient
        .mutate({
          mutation: circularResolutionVoteResult,
          variables: {
            outcomeDescription: resolution.outcomeDescription,
            resolutionUniqueId: resolution.resolutionUniqueId,
            voteOutcome: resolution.voteOutcome,
          },
        })
        .then((response) => {
          let tmp = response.data.circularResolutionVoteResult.success;
          context.dispatch("showLoadingSpinner", false);

          if (tmp) {
            swal.fire({
              toast: true,
              icon: "success",
              title: "Circular Resolution closed successfully",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            swal.fire({
              toast: true,
              icon: "error",
              title: "Failed to close Circular Resolution",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async publishDraftResolution(context, draftdata) {
      await apolloClient
        .mutate({
          mutation: publishDraftCircularResolution,
          variables: {
            input: {
              resolutionUniqueId: draftdata.resolutionUnqueId,
              publishing: draftdata.publishing,
              boardUniqueId: localStorage.getItem("my-boardUniqueId"),
            },
          },
        })
        .then((response) => {
          if (response.data.publishDraftCircularResolution.response.status) {
            context.commit("updateResolutionPublicationStatus", {
              publishing: draftdata.publishing,
              resolutionUnqueId: draftdata.resolutionUnqueId,
            });

            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.publishDraftCircularResolution.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          }else{

            swal.fire({
                toast: true,
                icon: "success",
                title: "Fail to complete the operation. Please try again.",
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });

          }
        })
        .catch(async (error) => {
          console.error(error);
        });
    },

    async deleteDraftResolution(context, draftdata) {
      await apolloClient
        .mutate({
          mutation: deleteDraftCircularResolution,
          variables: {
            input: {
              resolutionUniqueId: draftdata.resolutionUnqueId,
              boardUniqueId: localStorage.getItem("my-boardUniqueId"),
            },
          },
        })
        .then((response) => {
          if (response.data.deleteDraftCircularResolution.response.status) {
            context.commit("deleteResolutionPublicationStatus", {
              resolutionUnqueId: draftdata.resolutionUnqueId,
            });

            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.deleteDraftCircularResolution.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          }else{

            swal.fire({
                toast: true,
                icon: "success",
                title: "Fail to complete the operation. Please try again.",
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });

          }
        })
        .catch(async (error) => {
          console.error(error);
        });
    },

    async loadResolutionsComments(context, resolution) {
      await apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: resolutionComments,
          variables: {
            resolutionUniqueId: resolution.resolutionUnqueId
          },
        })
        .then((response) => {
          context.commit("addResolutionComments", response.data.resolutionComments);
        })
        .catch(async (error) => {
          console.error(error);
        });
    },

    async addResolutionComments(context, comment) {
      console.log(comment);
      await apolloClient
        .mutate({
          mutation: addResolutionComments,
          variables: {
            input: {
                resolutionUniqueId: comment.resolutionUniqueId,
                commentsDescriptions: comment.commentsDescriptions
            },
          },
        })
        .then((response) => {
          if (response.data.addResolutionComments.response.status) {
            context.commit('appendResolutionComments',response.data.addResolutionComments.data)
            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.addResolutionComments.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          }else{

            swal.fire({
                toast: true,
                icon: "success",
                title: "Fail to complete the operation. Please try again.",
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });

          }
        })
        .catch(async (error) => {
          console.error(error);
        });
    },

    async addResolutionSupportingDocuments(context, data) {
      await apolloClient
        .mutate({
          mutation: addResolutionSupportingAttachments,
          variables: {
            input: data.input,
          },
        })
        .then((response) => {
          if (response.data.addResolutionSupportingAttachments.response.status) {
            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.addResolutionSupportingAttachments.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              response.data.addResolutionSupportingAttachments.data.forEach(doc => {
               context.commit('appendResolutionDocuments',{doc:doc, key:data.resolution.resolutionUniqueId})
              });
          }else{

            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.addResolutionSupportingAttachments.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });

          }
          context.commit('clearCircularResolutionSuppotingDocuments')
        })
        .catch(async (error) => {
          console.error(error);
        });
    },
  },
};
