<template>
  <v-dialog v-model="openMeetingInterestsWindow" max-width="70%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          <v-icon color="indigo">mdi-playlist-star</v-icon> Board Members with Conflict of
          Interest for this Meeting</span
        >
      </v-card-title>
      <v-card-text>


        <div :class="`border-b-2 p-3 rounded flex ${ interest.conflictedAgendaItems.length && !interest.conflictedAgendaItems[0].itemName ? 'noConflict' : ''}`"
             v-for="(interest, index) in listOfInterest" :key="interest.primaryKey">
          <span class="font-medium">{{ index+1 }}.</span>
          <div class="ml-3 grow flex flex-col">
            <div class="grid grid-cols-3">
              <div class="flex flex-column col-span-1">
                <span class="font-medium">Member</span>
                <span class="text-primary">{{ interest.member }}</span>
              </div>
              <template v-if="interest.conflictedAgendaItems.length && interest.conflictedAgendaItems[0].itemName">
                <div class="flex flex-column col-span-1">
                  <span class="font-medium">Organization</span>
                  <span class="text-primary">{{ interest.interestOrganisation }}</span>
                </div>
                <div class="flex flex-column col-span-1">
                  <span class="font-medium">Interest</span>
                  <span class="text-primary">{{ interest.interestNature }}</span>
                </div>
              </template>
              <div v-else class="col-span-2 flex flex-justify-content-end">
                <div>
                  <span class="noConflictBubble px-2 py-1 rounded-full">Has No Conflict</span>
                </div>
              </div>

            </div>
            <div class="bg-primary/10 p-2 mt-1 rounded" v-if="interest.conflictedAgendaItems.length && interest.conflictedAgendaItems[0].itemName">
              <div>Conflicted Agenda</div>
              <div class="flex flex-wrap">
                <div class="border-r border-white"
                    v-for="(item,index) in interest.conflictedAgendaItems" :key="index">
                  <v-chip color="primary">
                    <span class="mr-2">{{index+1}}. {{item.itemName}}</span>
                  </v-chip>
                  <v-btn @click="deleteConflictOfInterest(item.declarationUniqueId)"
                         color="pink"
                         small icon>
                    <v-icon color="pink">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red"
            class="text-capitalize mr-2 mb-2"
            right
            small
            @click="closeNewInterestFormDialog">
          <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
          <span class="text-white">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from "@/views/Icon.vue";
export default {
  components: {Icon},
  props: ["openMeetingInterestsWindow", "meetingUniqueId", "agendaItems"],
  data() {
    return {
    };
  },

  methods: {
    closeNewInterestFormDialog() {
      this.$emit("closeNewInterestFormDialog");
    },

    async deleteConflictOfInterest(declarationUniqueId){
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch('deleteInterestRegisterAgendaItem',declarationUniqueId)
      await this.$store.dispatch('loadMeetingConflictsOfInterest',this.meetingUniqueId)
      await this.$store.dispatch("showLoadingSpinner", false);
    }
  },
  computed:{
    ...mapGetters({
      listOfInterest:'getMeetingConflictsOfInterest'
    })
  }
};
</script>

<style scoped>
.noConflict {
  background-color: rgba(0, 128, 0, 0.10);
}

.noConflictBubble {
  background-color: rgba(0, 128, 0, 0.2);
  color: rgb(2, 115, 2);
}
</style>
