import {apolloClient} from '@/vue-apollo';
import meetingByInstitution from '../../graphql/eboard-meetings-graphql/meetingByInstitution.gql';
import committeesMeetingByInstituionUniqueId
    from '../../graphql/eboard-meetings-graphql/committeesMeetingByInstituionUniqueId.gql';
import creatingMeeting from '../../graphql/eboard-meetings-graphql/creatingMeeting.gql';
import meetingDetails from '../../graphql/eboard-meetings-graphql/meetingDetails.gql';
import postponeMeeting from '../../graphql/eboard-meetings-graphql/postponeMeeting.graphql';
import changeAgendaVoteStatus from '../../graphql/eboard-meetings-graphql/changeAgendaVoteStatus.graphql';
import deleteMeeting from '../../graphql/eboard-meetings-graphql/deleteMeeting.gql';
import addMeetingInvitees from '../../graphql/eboard-meetings-graphql/addMeetingInvitees.gql';
import unConfirmMeetingByInstitution from '../../graphql/eboard-meetings-graphql/unConfirmMeetingByInstitution.gql';
import meetinngAttendees from '../../graphql/eboard-attendees-graphql/meetinngAttendees.gql';
import switchingAttendees from '../../graphql/eboard-attendees-graphql/switchingAttendees.gql';
import addMeetingAttandees from '../../graphql/eboard-attendees-graphql/addMeetingAttandees.gql';
import takeMeetingNotes from '../../graphql/eboard-meetings-graphql/takeMeetingNotes.graphql';
import approveMeetingMinutes from '../../graphql/eboard-meetings-graphql/approveMeetingMinutes.graphql';
import submitMeetingMinutes from '../../graphql/eboard-meetings-graphql/submitMeetingMinutes.gql';
import updateMeetingDetails from '../../graphql/eboard-meetings-graphql/updateMeetingDetails.gql';
import swal from 'sweetalert2';
import updateBoardMeetingAttendancePositionMutation
    from "@/graphql/eboard-meetings-graphql/updateBoardMeetingAttendancePositionMutation.gql";


export default {
    state: {
        boardMeetingsList: [],
        boardCommiteesMeetingsList: [],
        selectedMeetingDetails: null,
        meetinngAttendees: [],
        unConfirmMeetings: [],
        unConfirmCommitteeMeetings: []
    },

    mutations: {
        cleanMeetings(state) {
            state.boardMeetingsList = []
        },
        addMeetings(state, meetingObj) {
            state.boardMeetingsList = meetingObj
        },
        addCommitteesMeetings(state, meetings) {
            state.boardCommiteesMeetingsList = meetings
        },
        claenSelectedMeetingDetails(state) {
            state.selectedMeetingDetails = null
        },
        setSelectedMeetingDetails(state, meetingDetail) {
            state.selectedMeetingDetails = meetingDetail
        },
        cleanMeetingAttendees(state) {
            state.meetinngAttendees = []
        },
        setMeetingAttendees(state, attendees) {
            state.meetinngAttendees.push(attendees)
        },
        switchMeetingAttendees(state, attendees) {
            var attendee = state.meetinngAttendees.find(attend => {
                return attend.id === attendees.data
            })
            attendee.attendanceAcceptInvitation = attendees.side
        },
        addUnConfirmMeetings(state, meetingObject) {
            state.unConfirmMeetings.push(meetingObject)
        },
        addUnConfirmCommtteesMeetings(state, meetingObject) {
            state.unConfirmCommitteeMeetings.push(meetingObject)
        },

        cleanUnConfirmMeetings(state) {
            state.unConfirmMeetings = []
            state.unConfirmCommitteeMeetings = []
        },

        deleteBoardMeetings(state, meetingUniqueId) {
            var temp_list = []
            state.boardMeetingsList.forEach(meeting => {
                console.log(meeting);
                if (meeting.meetingUniqueId !== meetingUniqueId) {
                    temp_list.push(meeting)
                }
            });

            // state.boardMeetingsList = temp_list
        }
    },

    getters: {
        meetingsList(state) {
            return state.boardMeetingsList
        },
        committeeMeetingsList(state) {
            return state.boardCommiteesMeetingsList
        },
        getMeetingDetails(state) {
            return state.selectedMeetingDetails
        },
        getMeetingAttendees(state) {
            return state.meetinngAttendees.filter(attendee => {
                return attendee.attendanceAcceptInvitation
            })
        },
        getMeetingApologiesAttendees(state) {
            return state.meetinngAttendees.filter(attendee => {
                return !attendee.attendanceAcceptInvitation
            })
        },
    },

    actions: {
        async loadBoardMeetingsList(context) {
            context.commit('cleanMeetings',)
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingByInstitution,
                variables: {
                    instituionUniqueId: localStorage.getItem('my-boardUniqueId'),
                    meetingHasConducted: false
                }
            }).then((response) => {
                const meetings = response.data.meetingByInstituionUniqueId.results
                context.commit('addMeetings', meetings)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadMeetingByBoardUniqueId(context) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingByInstitution,
                variables: {
                    instituionUniqueId: localStorage.getItem('my-boardUniqueId'),
                    meetingMinutesConfirmed: false
                }
            }).then((response) => {
                const meetings = response.data.meetingByInstituionUniqueId.results
                context.commit('addMeetings', meetings)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createBoardMeeting(context, meetingBody) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: creatingMeeting,
                variables: {
                    meetingTitle: meetingBody.meetingTitle,
                    meetingDate: meetingBody.meetingDate,
                    meetingStartTime: meetingBody.meetingStartTime,
                    meetingEndTime: meetingBody.meetingEndTime,
                    meetingLocation: meetingBody.meetingLocation,
                    meetingBoard: localStorage.getItem("my-boardUniqueId"),
                    meetingHasInvitations: false,
                    meetingCategory: meetingBody.meetingCategory,
                    meetingCommittees: meetingBody.meetingCommittees
                }
            }).then((response) => {
                const meeting = response.data.createMeeting.meeting
                context.dispatch("showLoadingSpinner", false);
                console.log("meeting");
                console.log(meeting);
                context.commit('addMeetings', meeting)
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Meeting registered successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async postponeBoardMeeting(context, meetingBody) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: postponeMeeting,
                variables: {
                    meetingDate: meetingBody.meetingDate,
                    meetingEndTime: meetingBody.meetingEndTime,
                    meetingLocation: meetingBody.meetingLocation,
                    meetingStartTime: meetingBody.meetingStartTime,
                    meetingUniqueId: meetingBody.meetingUniqueId
                }
            }).then((response) => {
                const success = response.data.postponeMeeting.success

                context.commit('fetchingMeetingDetails', meetingBody.meetingUniqueId)
                context.dispatch("showLoadingSpinner", false);

                if (success) {

                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: response.data.postponeMeeting.message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: response.data.postponeMeeting.message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async fetchingMeetingDetails(context, currentMeeting) {
            context.commit('claenSelectedMeetingDetails')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingDetails,
                variables: {
                    meetingUniqueId: currentMeeting
                }
            }).then((response) => {
                const meeting = response.data.getMeetingByUniqueId.edges[0].node
                context.commit('setSelectedMeetingDetails', meeting)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            })
        },

        async updateBoardAttendeesMeetingPosition(context, data) {
            await apolloClient.mutate({
                mutation: updateBoardMeetingAttendancePositionMutation,
                variables: {
                    user: data.user,
                    position: data.position
                }
            }).then(() => {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Updated',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },

        async fetchingMeetingAttendees(context, currentMeeting) {
            context.commit('cleanMeetingAttendees')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetinngAttendees,
                variables: {
                    meetingUniqueId: currentMeeting
                }
            }).then((response) => {
                const attendees = response.data.meetingAttendees.edges
                attendees.forEach(attendee => {
                    var atDef = {
                        id: attendee.node.primaryKey,
                        name: attendee.node.attendanceUser.firstName + " " + attendee.node.attendanceUser.lastName,
                        attendanceAcceptInvitation: attendee.node.attendanceAcceptInvitation,
                        attendancePosition: attendee.node.attendancePosition,
                        userId: attendee.node.attendanceUser.username
                    }
                    context.commit('setMeetingAttendees', atDef)
                    context.dispatch("showLoadingSpinner", false);
                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async takeMeetingNotes(context, meeting) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: takeMeetingNotes,
                variables: {
                    meetingNotes: meeting.meetingNotes,
                    meetingUniqueId: meeting.meetingUniqueId
                }
            }).then((response) => {
                console.log(response.data.takeMeetingNotes.success)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async approveMeetingMinutes(context, meetingUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: approveMeetingMinutes,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                let success = response.data.approveMeetingMinutes.success

                context.commit('fetchingMeetingDetails', meetingUniqueId)
                context.dispatch("showLoadingSpinner", false);

                if (success) {

                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Minutes Approved Successfully",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Failed to Approve Minutes",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async submitMeetingMinutes(context, meetingUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: submitMeetingMinutes,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                let success = response.data.submitMeetingMinutes.success;
                context.dispatch("showLoadingSpinner", false);
                // context.commit('fetchingMeetingDetails', meetingUniqueId);

                if (success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Minutes Submitted Successfully",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Failed to Submitted Minutes",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async addMeetingAttandees(context, attendee) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: addMeetingAttandees,
                variables: {
                    attendanceMeeting: attendee.attendanceMeeting,
                    attendanceUser: attendee.attendanceUser
                }
            }).then((response) => {
                const attendee = response.data.addMeetingAttandees.attendance
                var atDef = {
                    id: attendee.primaryKey,
                    name: attendee.attendanceUser.firstName + " " + attendee.attendanceUser.lastName,
                    attendanceAcceptInvitation: attendee.attendanceAcceptInvitation,
                    userId: attendee.attendanceUser.username
                }
                context.commit('setMeetingAttendees', atDef)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async switchingMeetingAttendees(context, data) {
            await apolloClient.mutate({
                mutation: switchingAttendees,
                variables: {
                    attendenceKey: data.data,
                    acceptInvitation: data.side
                }
            })
            context.commit('switchMeetingAttendees', data)
        },

        async loadUnConfirmMeetings(context) {
            context.commit('cleanUnConfirmMeetings')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: unConfirmMeetingByInstitution,
                variables: {
                    instituionUniqueId: localStorage.getItem('my-boardUniqueId'),
                }
            }).then((response) => {
                const meetings = response.data.unConfirmedMeetingByInstituionUniqueId.results
                meetings.forEach(meeting => {
                    context.commit('addUnConfirmMeetings', {
                        meetingTitle: meeting.meetingTitle + " [ " + meeting.meetingDate + " ]",
                        primaryKey: meeting.primaryKey,
                        minutesApproved: meeting.meetingMinutesApproved
                    })
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateMeetingInvitees(context, inviteeeData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: addMeetingInvitees,
                variables: {
                    meetingUniqueId: inviteeeData.meetingUniqueId,
                    invitees: inviteeeData.invitees
                }
            }).then((response) => {
                const measage = response.data.addMeetingInvitees.success
                context.dispatch("showLoadingSpinner", false);
                console.log(measage);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async changeAgendaVoteStatus(context, voteObj) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: changeAgendaVoteStatus,
                variables: {
                    voteUniqueId: voteObj.voteUniqueId,
                    voteIsAnonymous: voteObj.voteIsAnonymous
                }
            }).then((response) => {
                const measage = response.data.addMeetingInvitees.success
                context.dispatch("showLoadingSpinner", false);
                console.log(measage);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteBoardMeeting(context, meetingUniqueId) {
            context.commit('cleanMeetings',)
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: deleteMeeting,
                variables: {
                    meetingUniqueId: meetingUniqueId,
                }
            }).then((response) => {
                const saved = response.data.deleteMeeting.meeting.saved;
                context.commit('deleteBoardMeetings', meetingUniqueId)
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadBoardCommitteesMeetingsList(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: committeesMeetingByInstituionUniqueId,
                variables: {
                    instituionUniqueId: localStorage.getItem('my-boardUniqueId'),
                    committeeUniqueId: localStorage.getItem('my-boardCommitteeUniqueId'),
                    meetingHasConducted: false
                }
            }).then((response) => {
                const meetings = response.data.committeesMeetingByInstituionUniqueId
                context.commit('addCommitteesMeetings', meetings);
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateMeetingDetails(context, Meeting) {
            apolloClient.mutate({
                mutation: updateMeetingDetails,
                variables: {
                    meetingUniqueId: Meeting.meetingUniqueId,
                    updateType: Meeting.updateType,
                    updateValue: Meeting.updateValue,
                }
            }).then((response) => {
                const saved = response.data.updateMeetingDetails.message;
                const success = response.data.updateMeetingDetails.success;
                console.log(saved, success)
                // await context.dispatch("fetchingMeetingDetails", Meeting.meetingUniqueId);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            })
        },

    }
}
