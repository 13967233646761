<template>
  <v-container>
    <v-sheet class="pa-5 ml-4" outlined>
      <v-list dense>
        <v-subheader>Meeting Minutes Section Buttons</v-subheader>
        <v-btn
          @click="createButtonDialog = true"
          color="primary"
          block
          plain
          outlined
          ><v-icon>mdi-plus</v-icon></v-btn
        >
        <div class="mt-5">
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item dense v-for="(section, i) in MinutesCustomSections" :key="i + 1">
              <v-list-item-icon>
                <v-icon :color="section.sectionButtonColor" v-text="'mdi-sticker-text-outline'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="section.sectionButtonLabel"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="deleteCustomMinuteButton(section.sectionUniqueId)" icon>
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item dense v-for="(item, i) in defined" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="grey lighten-1">mdi-cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
    </v-sheet>
    <v-dialog v-model="createButtonDialog" max-width="60%">
      <v-card>
        <v-card-title class="text-h6">
          <v-icon style="margin-right: 10px">mdi-blur</v-icon>
          Add Section
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="buttonLabel"
            dense
            label="Button Label"
            outlined
            clearable
          ></v-text-field>
          <v-radio-group v-model="buttonColor">
          <v-layout justify-space-between>
            <v-radio class="ml-2" label="Primary" color="primary" value="primary"></v-radio>
            <v-radio class="ml-2" label="Secondary" color="secondary" value="secondary"></v-radio>
            <v-radio class="ml-2" label="Success" color="success" value="success"></v-radio>
            <v-radio class="ml-2" label="Info" color="info" value="info"></v-radio>
            <v-radio class="ml-2" label="Orange" color="warning" value="warning"></v-radio>
            <v-radio class="ml-2" label="Red" color="error" value="error"></v-radio>
          </v-layout>
          </v-radio-group>
          <v-layout justify-end>
            <v-btn
              color="green darken-1"
              text
              small
              @click="$emit('CloseCreateFolderDialog')"
            >
              Cancel
            </v-btn>

            <v-btn @click="addNewCustomMinuteButton" color="green darken-1" text small> Save </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      buttonLabel: "",
      buttonColor: "",
      createButtonDialog: false,
      items: [{ text: "Tabled", icon: "mdi-table-furniture" }],
      defined: [
        { text: "Noted", icon: "mdi-sticker-text-outline" },
        { text: "Directive", icon: "mdi-script-text-outline" },
        { text: "Resolution", icon: "mdi-flag" },
      ],
    };
  },
  computed:{

    MinutesCustomSections() {
      return this.$store.state.SettingsStore.boardCustomMinutesSectionsList;
    },
  },
  methods:{
    async addNewCustomMinuteButton(){
      await this.$store.dispatch("showLoadingSpinner", true);
      var btnData={
        sectionButtonColor:this.buttonColor,
        sectionButtonLabel:this.buttonLabel,
      }
      await this.$store.dispatch("SettingsStore/addCustomMinuttesButtons", btnData);
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings");
      this.buttonColor=""
      this.buttonLabel=""
      this.createButtonDialog=false
      await this.$store.dispatch("showLoadingSpinner", false);
    },
    async deleteCustomMinuteButton(sectionUniqueId){
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("SettingsStore/deleteCustomMinuttesButtons", sectionUniqueId);
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings");
      await this.$store.dispatch("showLoadingSpinner", false);
    }
  }
};
</script>

<style>
</style>
