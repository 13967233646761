<template>
  <div class="my-2">
    <v-row class="p-5">
      <h2 class="mr-auto mt-5 ml-10 text-lg font-weight-medium">Recent Published News</h2>
      <v-spacer></v-spacer>
      <div class="relative flex-end border border-primary rounded-md mr-5">
        <input v-model="searchQuery" type="text" class="form-control py-3 px-4 w-full lg:w-64 box pr-10" placeholder="Search News...">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            icon-name="search"
            class="lucide lucide-search w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            data-lucide="search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </div>
    </v-row>
    <div class="p-5" v-for="news in displayedItems" :key="news.id">
      <div class="intro-y box col-span-12 2xl:col-span-6 border border-primary">
        <div class="flex items-center px-5 py-3 border border-slate-400/60 dark:border-darkmode-400">
          <v-avatar tile size="40">
            <img
                :aspect-ratio="3 / 3"
                :key="news.value"
                src="@/assets/news.png"
                alt="draft"
            />
          </v-avatar>
          <h2 class="font-medium text-base mr-auto ml-2">
            {{ news.newsTitle }}
          </h2>
        </div>
        <div class="px-5" >
          <div class="text-slate-600 dark:text-slate-500 mt-2">
            {{news.newsDescriptions}}
          </div>
          <div class="flex items-center mt-5 mb-2">
            <v-spacer></v-spacer>
            <div class="px-3 py-2 text-primary bg-primary/10 dark:bg-darkmode-400 dark:text-slate-300 rounded font-medium">Valid until: {{news.newsExpireDate | formatFull}}</div>

              <v-btn
                  @click="viewNewsDocuments(news)"
                  text
                  class="ma-1 rounded-full"
                  small
                  outlined
              ><v-icon small color="grey">mdi-paperclip</v-icon>
                Documents
              </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-spacer></v-spacer>
      <v-pagination
        v-model="boardPublishedUpdates"
        :length="boardPublishedUpdates.length/2"
        @input="onPageChange"
        class="mr-10"
      >
      </v-pagination>
    </v-row>
      <published-documents
        @close-dialog="filePreviewDialog=false"
        :file-preview-dialog="filePreviewDialog"
        :news-unique-id="newsUniqueId"
        :news-title="newsTitle"
      >
      </published-documents>
  </div>
</template>

<script>


import PublishedDocuments from "@/modules/meeting_management/eboard_news_updates/PublishedDocuments.vue";

export default {
  components: {PublishedDocuments},
  data: () => ({
    filePreviewDialog: false,
    hideDelimiters: true,
    openvalue: false,
    switch1: "",
    readColor: "success",
    date: null,
    trip: {
      name: "",
      location: null,
      start: null,
      title: null,
      description: null,
      end: null,
    },

    searchQuery: "",
    newsUniqueId: "",
    newsTitle: "",
    page: 1,
    itemsPerPage: 3,

    docsCount: 0
  }),

  computed: {

    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    displayedItems() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.boardPublishedUpdates.slice(startIndex, endIndex);
    },

    boardPublishedUpdates() {
      let published_news =  this.$store.state.NewsUpdatesStore.boardPulishedNewsUpdatesList;
      if (this.searchQuery=="") {
        return published_news
      }else{
        return published_news.filter(news => {
          return news.newsTitle.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardPublishedNewsUpdates");
  },

  methods: {

    getDocsCount(countDocs){
      this.docsCount = countDocs
    },

    onPageChange(page) {
      this.page = page;
    },

    openSupportingDocument(docs) {
      this.suppotingDocs = [];
      this.suppotingDocs = docs;
      this.filePreviewDialog = true;
    },

    async viewNewsDocuments(news){
      this.filePreviewDialog = true
      this.newsTitle = news.newsTitle
      await this.$store.dispatch('getNewsAndUpdatesDocuments', news.newsUniqueId)
    }
  },
};
</script>

<style>
</style>
