<template>
  <nav>
    <v-app-bar  dense  app style="background: #3f51b5;color: #fff;">
      <v-app-bar-nav-icon><v-icon  @click="mini = !mini" class="white--text" left>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <router-link to="/management/management_my_dashboard" style="text-decoration: none">
          <span class="white--text">{{ currentManagement }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon>
        <router-link to="/" style="text-decoration: none">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" left>mdi-help-circle-outline</v-icon>
          </template>
          <v-list>
            <v-list-item link >
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-note-text</v-icon> User Manual</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--          <v-list-item link >-->
            <!--            <v-list-item-content>-->
            <!--              <v-list-item-title><v-icon>mdi-information-variant</v-icon> About e-Board</v-list-item-title>-->
            <!--            </v-list-item-content>-->
            <!--          </v-list-item>-->
          </v-list>

        </v-menu>
      </v-app-bar-nav-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" right>mdi-chevron-down</v-icon>
          <span style="padding-left:10px">{{logeduser.fullname}}</span>
        </template>
        <v-list>
          <v-list-item v-for="link in topLinks" :key="link.name" link :to="link.routinglink">
            <v-list-item-content>
              <v-list-item-title><v-icon>{{ link.icon }}</v-icon> {{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="!hasHandoverDuties()" @click="handoverDialog=true">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-account-switch</v-icon>
                Handover Duties
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="changePassword">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-lock</v-icon>
                Change Password
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="callLogoutMethod" >
            <v-list-item-content>
              <v-list-item-title><v-icon>mdi-power</v-icon> Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar >

    <ManagementHandOverForm
        @update:handoverDialog="handoverDialog=false"
      :handover-dialog="handoverDialog"
      ></ManagementHandOverForm>

    <v-card >
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
          v-if="!hasHandoverDuties()"
          app
      >
        <v-list-item class="px-2 intro-y ">
          <v-list-item-avatar>
            <v-img :src="MEDIA_SERVER +(institutionDetails()||{}).institutionLogo"></v-img>
          </v-list-item-avatar>
          <v-spacer></v-spacer>

          <v-btn
              icon
              @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <template v-for="link in links">
            <v-list-item class="-intro-x" link :to="link.routinglink" :key="link.name" v-if="hasAccess(link.permision)" >
              <v-list-item-icon  class="iconclass">
                <v-icon style="color:rgba(63, 81, 181, 0.90)">{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn link to="eboard_my_profile"  right fab x-small color="blue white--text" style="margin:5px"><v-icon>mdi-account-tie</v-icon></v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>
  </nav>
</template>

<script>
import allBoardsByUser from '../../../../graphql/eboard-boards-graphql/allBoardsByUser.gql'
import store from '@/store'
import ManagementHandOverForm from './ManagementHandOverForm.vue'

export default {
  components: { ManagementHandOverForm },
  data() {
    return {
      board_account:"/board_account/",
      handoverDialog: false,
      drawer: true,
      mini:false,
      topLinks:[
        {
          name:'My Profile',
          icon:'mdi-account',
          routinglink:'/management/management_my_profile'
        },
        // {
        //   name:'Help',
        //   icon:'mdi-account',
        //   routinglink:'management_meetings/my-eboards'
        // },
      ],
      links:[
        {
          name:'My Dashboard',
          icon:'mdi-view-dashboard',
          routinglink:'/management/management_my_dashboard',
          permision:'can_view_management_dashboards'
        },
        {
          name:'Meetings Schedule',
          icon:'mdi-calendar',
          routinglink:'/management/management_account/'+localStorage.getItem("my-managementUniqueId")+"/My-Board",
          permision:'can_view_management_meeting'
        },
        {
          name:'Directives',
          icon:'mdi-run-fast',
          routinglink:'/management/management_directives_categories',
          permision:'can_view_management_directives'
        },
        {
          name:'Resolution Register',
          icon:'mdi-stamper',
          routinglink:'/management/management_decisions',
          permision:'can_view_management_decisions'
        },
        {
          name:'Meeting Repository',
          icon:'mdi-palette-swatch',
          routinglink:'/management/management_meeting_repository',
          permision:'can_view_management_repository'
        },
        {
          name:'Periodic Reports',
          icon:'mdi-chart-box-plus-outline',
          routinglink:'/management/management_reports_dashboard',
          permision:'can_view_management_report'
        },

        {
        name:'Management Structure',
        icon:'mdi-react',
        routinglink:'/management/management_structure',
        permision:'can_view_management_report'
        },
        {
          name:'Management Reports',
          icon:'mdi-file-document-outline',
          routinglink:'/management/management_reports',
          permision:'can_view_management_report'
        },
      ],
      logeduser:{'fullname':""}
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed:{
    currentManagement(){
      return this.$store.state.AuthStore.user.managementUser.edges[0].node.managementMemberManagement.managementName
    }
  },

  methods:{
    callLogoutMethod(){
      localStorage.removeItem('vuex')
      localStorage.removeItem('my-boardUniqueId')
      localStorage.removeItem('my-managementCommitteeName')
      localStorage.removeItem('my-managementCommitteeUniqueId')
      localStorage.removeItem('my-boardCommitteeName')
      localStorage.removeItem('my-boardCommitteeUniqueId')
      localStorage.removeItem('my-boardKey')
      localStorage.removeItem('apollo-token')
      this.$router.go('/landing_page')
    },
    changePassword() {
      this.$router.push('/change-password')
    },
    changeSelectedBoard(board_uuid,board_name){
      this.$store.dispatch('updateCurrentMyboardName',board_name)
      localStorage.setItem('my-boardUniqueId',board_uuid)
      this.$router.go('')
    }
  },
  mounted(){
    const userDetails = store.getters['AuthStore/user']
    this.logeduser.fullname=userDetails.firstName +" "+userDetails.lastName
  }
}
</script>

