var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.showContextMenu),callback:function ($$v) {_vm.showContextMenu=$$v},expression:"showContextMenu"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_c('v-icon',[_vm._v("mdi-account-group-outline")]),_vm._v(" Add a meeting")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"counter":100,"label":"Title *","required":"","prepend-icon":"mdi-text-recognition"},model:{value:(_vm.meetingTittle),callback:function ($$v) {_vm.meetingTittle=$$v},expression:"meetingTittle"}}),_c('v-select',{attrs:{"items":_vm.meetingCategories,"item-text":"title","item-value":"key","label":"Meeting Category *","required":"","prepend-icon":"mdi-shape-outline"},on:{"change":_vm.meetingCategorySelected},model:{value:(_vm.meetingCategory),callback:function ($$v) {_vm.meetingCategory=$$v},expression:"meetingCategory"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.meetingDate),callback:function ($$v) {_vm.meetingDate=$$v},expression:"meetingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.date},model:{value:(_vm.meetingDate),callback:function ($$v) {_vm.meetingDate=$$v},expression:"meetingDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog1",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[(_vm.modal1)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"min":_vm.startTime,"full-width":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"return-object":"","items":_vm.locations,"item-text":"location","label":"Location *","required":"","prepend-icon":"mdi-map-marker","hint":_vm.timezone,"persistent-hint":""},on:{"change":_vm.getSelectedTimeZone},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"","small":""}},[_vm._v(_vm._s(item.location))])]}}]),model:{value:(_vm.defaultSelected),callback:function ($$v) {_vm.defaultSelected=$$v},expression:"defaultSelected"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden),expression:"!hidden"}],attrs:{"x-small":"","color":"primary","text":""},on:{"click":function($event){_vm.openLocationDialog = !_vm.openLocationDialog}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add a Meeting Location")],1)],1)],1),_c('v-divider')],1),_c('v-card-actions',{staticClass:"mb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"pink","small":"","outlined":""},on:{"click":function($event){return _vm.$emit('closeNewMeeitngDialog')}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Close ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"blue darken-1","outlined":"","small":""},on:{"click":_vm.createNewBoardMeeting}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-floppy")]),_vm._v(" Save ")],1)],1)],1)],1),_c('LocationSettings',{attrs:{"title":"Add New Location","action":"Add","openLocationDialog":_vm.openLocationDialog},on:{"closeNewLocationDialog":function($event){_vm.openLocationDialog = !_vm.openLocationDialog}}}),_c('v-overlay',{attrs:{"z-index":_vm.zIndex,"value":_vm.overlay}},[_c('v-card',{attrs:{"color":"indigo","dark":"","width":"300"}},[_c('v-card-text',[_vm._v(" We are Setting Up Meeting Please wait . . . "),_c('v-progress-linear',{staticClass:"mb-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }