<template>
  <v-card tile>
    <v-card-title>
      <strong
        ><span
          class="maincolor"
          contenteditable="true"
          style="padding-left: 10px"
          >{{ selectedAgenda.itemName }}</span
        ></strong
      >
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row justify-space-around>
          <v-card-text v-if="selectedAgenda.itemType === 'Standard'">
            <v-container>
              <v-row justify-space-around>
                <AgendaTopDetails />
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text v-else-if="selectedAgenda.itemType === 'ConfirmMinutes'">
            <v-container>
              <v-row justify-space-around>
                <ConfirmMinutesAgendaTopDetails />
                <v-list dense width="100%">
                  <v-list-item-group color="primary">
                    <v-layout
                      style="padding-top: 10px; width: 100%"
                      width="100%"
                    >
                      <v-alert dense style="width: 100%" text>
                        <v-flex md12 xs12>
                          <div class="">
                            <span>Minutes to be Confirmed:</span>
                          </div>
                        </v-flex>
                      </v-alert>
                    </v-layout>
                    <v-list-item
                      style="
                        border-top: 1px solid gray;
                        border-bottom: 1px solid gray;
                      "
                      @click="
                        OpenfileDialogFunc(
                          '/' +
                            selectedAgenda.itemMinutesDetails.edges[0].node
                              .documentPath
                        )
                      "
                    >
                      <v-list-item-avatar size="30">
                        <v-img src="@/assets/docs.png" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            selectedAgenda.itemMinutesDetails.edges[0].node
                              .documentName
                          "
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-menu app offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              slot="activator"
                              color="indigo"
                              flat
                              icon
                              v-bind="attrs"
                              x-small
                              v-on="on"
                            >
                              <v-icon small>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              link
                              to=""
                              @click="
                                OpenfileDialogFunc(
                                  '/' +
                                    selectedAgenda.itemMinutesDetails.edges[0]
                                      .node.documentPath
                                )
                              "
                            >
                              <v-list-item-title>
                                <v-icon style="height: 16px; font-size: 16px"
                                  >mdi-focus-field-horizontal
                                </v-icon>
                                Preview
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="hasAccess('can_confirm_minutes') && showConfirm"
                              color="success"
                              link
                              to=""
                              @click="
                                ConfirmMeetingMinutes(selectedAgenda.itemKey)
                              "
                            >
                              <v-list-item-title>
                                <v-icon
                                  style="
                                    height: 16px;
                                    font-size: 16px;
                                    color: limegreen;
                                  "
                                  >mdi-check-all
                                </v-icon>
                                Confirm Minutes
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text v-else-if="selectedAgenda.itemType === 'Vote'">
            <v-container>
              <v-row justify-space-around>
                <VotingAgendaTopDetails />
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text
            v-else-if="selectedAgenda.itemType === 'InterestRegister'"
          >
            <v-container>
              <v-row justify-space-around>
                <AgendaTopDetails />
                <v-list dense width="100%">
                  <v-list-item-group color="primary">
                    <v-layout
                      style="padding-top: 10px; width: 100%"
                      width="100%"
                    >
                      <v-alert dense style="width: 100%" text>
                        <v-flex md12 xs12>
                          <div class="">
                            <span
                              >List of interests of directors and officers
                              including actual, potential or perceived
                              conflicts:</span
                            >
                          </div>
                        </v-flex>
                      </v-alert>
                    </v-layout>
                    <v-list-item>
                      <v-card elevation="0" width="100%">
                        <v-tabs style="content-align: left" vertical>
                          <v-tab
                            v-for="member in activeInterestList"
                            :key="member.primaryKey"
                          >
                            {{ member.firstName }} {{ member.lastName }}
                          </v-tab>

                          <v-tab-item
                            v-for="member in activeInterestList"
                            :key="member.interestKey"
                          >
                            <v-card>
                              <v-card-text>
                                <v-simple-table>
                                  <template>
                                    <thead>
                                      <tr>
                                        <th
                                          class="text-left"
                                          style="width: 30%"
                                        >
                                          <strong>Organization</strong>
                                        </th>
                                        <th
                                          class="text-left"
                                          style="width: 40%"
                                        >
                                          <strong>Interest</strong>
                                        </th>
                                        <th
                                          class="text-left"
                                          style="width: 20%"
                                        >
                                          <strong>Notified Date</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="interest in member.listOfInterest"
                                        :key="interest.primaryKey"
                                      >
                                        <td>
                                          <strong>{{
                                            interest.interestOrganisation
                                          }}</strong>
                                        </td>
                                        <td>
                                          {{ interest.interestNature }}
                                        </td>
                                        <td>
                                          {{
                                            interest.interestNotificationDate
                                              | formatDate
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-container>
            <AgendaSupportingDocs :data="selectedAgenda" type="Agenda" />
          </v-card-text>
        </v-row>

        <v-row v-if="selectedAgenda.itemType === 'Vote'" justify-space-around>
          <div>
            Vote Results
            <v-btn
              v-if="selectedAgenda.itemVoteDetails.edges[0].node.voteIsActive"
              color="primary"
              outlined
              small
              text
              @click="
                updateVoteResults(
                  selectedAgenda.itemVoteDetails.edges[0].node.voteUniqueId
                )
              "
            >
              <v-icon>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </div>
          <v-card-text>
            <v-layout justify-space-between>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="info lighten-1" dark>
                    mdi-account-group
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedVoteResults.voteVoters"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="'VOTTERS'"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="success lighten-1" dark> mdi-thumb-up</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedVoteResults.votersSupport"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="'SUPPORT'"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="pink lighten-1" dark> mdi-thumb-down</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedVoteResults.votersOppose"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="'OPPOSE'"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="grey lighten-1" dark> mdi-minus</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedVoteResults.votersAbstain"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="'ABSTAIN'"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-layout justify-space-start wrap>
              <v-btn
                v-if="selectedAgenda.itemVoteDetails.edges[0].node.voteIsActive"
                color="success"
                outlined
                small
                style="margin-left: 5px"
                @click="
                  closeVottingSession(
                    selectedAgenda.itemVoteDetails.edges[0].node.voteUniqueId
                  )
                "
                >Close Vote
              </v-btn>
              <v-btn
                v-if="
                  !selectedAgenda.itemVoteDetails.edges[0].node.voteIsActive
                "
                color="warning"
                outlined
                small
                style="margin-left: 5px"
                @click="closeVottingSession()"
                >Re-Open Vote
              </v-btn>
              <v-btn
                v-if="
                  !selectedAgenda.itemVoteDetails.edges[0].node.voteIsActive
                "
                color="success"
                outlined
                small
                style="margin-left: 5px"
                @click="openVoteDetails"
                >Vote Details
              </v-btn>
              <v-btn
                v-if="
                  !selectedAgenda.itemVoteDetails.edges[0].node.voteIsActive &&
                  !selectedAgenda.itemVoteDetails.edges[0].node.voteIsAnonymous
                "
                color="info"
                outlined
                small
                style="margin-left: 5px"
                @click="openVoteResultsDialog"
                >Vote Results
              </v-btn>
            </v-layout>
          </v-card-text>
        </v-row>
      </v-container>
    </v-card-text>

    <center><p style="padding-bottom: 0px">Add a Minute...</p></center>
    <v-card-actions style="padding-top: 0px; ">
      <v-spacer></v-spacer>
      <div class="diplay:flex; flex-direction: column">
        <v-btn
          v-for="button in MinutesCustomSections"
          :key="button.sectionUniqueId"
          class="ma-2"
          :color="button.sectionButtonColor"
          outlined
          small
          @click="
            loadPreviousCustomMinutesSections(
              button.sectionButtonLabel,
              button.sectionUniqueId
            )
          "
        >
          <v-icon small>mdi-file-document</v-icon>
          {{ button.sectionButtonLabel }}
        </v-btn>

        <v-btn
          class="ma-2"
          color="primary"
          outlined
          small
          @click="loadPreviousMeetingNotes('Add New Note'); $emit('reload')"
        >
          <v-icon small>mdi-file-document</v-icon>
          Noted
        </v-btn>

        <v-btn
          color="success"
          outlined
          small
          @click="loadPreviousMeetingDirective('Add New Directive'); $emit('reload')"
        >
          <v-icon small>mdi-checkbox-marked-outline</v-icon>
          Directives
        </v-btn>

        <v-btn
          class="ma-2"
          color="pink"
          outlined
          small
          @click="loadPreviousMeetingDecisions('Add Resolution'); $emit('reload')"
        >
          <v-icon small>mdi-arrow-all</v-icon>
          Resolution
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <MinuteNote
        :OpenMinuteNoteEditor="MinuteNoteEditor"
        :selectedAgenda="selectedAgenda"
        @CloseMinuteNoteEditor="MinuteNoteEditor = false"
        :noteTitle="noteTitle"
      />

      <NewAction
        :OpenActionEditor="ActionEditor"
        :actionName="actionName"
        :selectedAgenda="selectedAgenda"
        @CloseActionEditor="ActionEditor = false"
        :selectedDirective="selectedDirective"
      />

      <NewDecision
        :OpenDecisionEditor="DecisionEditor"
        :selectedAgenda="selectedAgenda"
        @CloseDecisionEditor="DecisionEditor = false"
        :decisionName="decisionName"
        :selectedDecision="selectedDecision"
      />
    </v-card-actions>

    <v-card-text>
      <v-card
        v-for="section in selectedCustomMinutesSectionsAgenda"
        :key="section.sectionUniqueId"
        class="mx-auto my-2 pa-3 agenda blocked"
        max-width="100%"
        outlined
      >
        <template slot=""> </template>
        <v-card-title>
          {{ section.sectionName }}:
          <v-spacer></v-spacer>
          <v-btn
            @click="
              loadPreviousCustomMinutesSections(
                section.sectionName,
                section.sectionButtonUniqueId
              )
            "
            icon
            :color="section.sectionColor"
          >
            <v-icon> mdi-pencil-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="section.sectionValue"></div>
        </v-card-text>
      </v-card>

      <v-card
        v-if="!hasNoNotes"
        class="mx-auto my-2 pa-3 agenda blocked"
        max-width="100%"
        outlined
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title>
          Note:
          <v-spacer></v-spacer>
          <v-btn
            @click="loadPreviousMeetingNotes('Edit Note')"
            icon
            color="info"
          >
            <v-icon> mdi-pencil-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="selectedAgendaNotes.notesDescriptions"></div>
        </v-card-text>
      </v-card>

      <v-card
        v-for="directive in selectedAgendaDirectives"
        :key="directive.directiveUniqueId"
        class="mx-auto my-2 pa-3 agenda complete"
        max-width="100%"
        outlined
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title>
          Directive: {{ directive.directiveTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="editAgendaItemDirective(directive, 'Edit Directive')"
            icon
            small
            color="info"
            class="mr-2"
          >
            <v-icon> mdi-pencil-circle </v-icon>
          </v-btn>
          <v-btn
            @click="deleteAgendaItemDirective(directive)"
            icon
            small
            color="error"
          >
            <v-icon> mdi-delete-circle </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-html="directive.directiveDescriptions"></div>
          <v-divider></v-divider>
          <p>
            <strong>Owner:</strong>
            {{ directive.directiveOwner.designationShortForm }}
          </p>
          <v-divider></v-divider>
          <p>
            <strong>Due Date:</strong>
            {{ directive.directiveDuedate | formatDate }}
          </p>
        </v-card-text>
      </v-card>

      <v-card
        v-for="decision in selectedAgendaDecisions"
        :key="decision.decisionUniqueId"
        class="mx-auto my-2 pa-3 agenda overdue"
        max-width="100%"
        outlined
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title>
          Resolution: {{ decision.decisionTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="editAgendaItemDecision(decision, 'Edit Resolution')"
            icon
            color="info"
          >
            <v-icon> mdi-pencil-circle </v-icon>
          </v-btn>
          <v-btn
            @click="deleteAgendaItemDecision(decision)"
            icon
            small
            color="error"
          >
            <v-icon> mdi-delete-circle </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-html="decision.decisionDescriptions"></div>
          <v-divider></v-divider>
          <p><strong>Outcome:</strong> {{ decision.decisionOutcome }}</p>
          <v-divider></v-divider>
          <p>
            <strong>Resolution Date:</strong>
            {{ decision.decisionDate | formatDate }}
          </p>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-text>
      <AgendaSupportingDocs />
      <AgendaSupportingDocs :data="selectedAgenda" type="Minutes" />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        class="ma-2"
        color="pink"
        outlined
        small
        @click="
          AgendaBuilderWindow = false;
          $emit('closeGreatModel');
        "
      >
        <v-icon small>mdi-close</v-icon>
        Close
      </v-btn>

      <v-btn
        v-if="hasAccess('can_preview_minutes')"
        class="ma-2"
        color="accent"
        outlined
        small
      >
        <v-icon small>mdi-archive-eye-outline</v-icon>
        Preview
      </v-btn>

      <v-btn
          v-if="hasAccess('can_submit_minutes') && !isMeetingMinutesSubmitted"
          :loading="loading3"
          color="success"
          small
          @click="submitMinutes"
          outlined
      >
        <v-icon small>mdi-check</v-icon>
        Submit
      </v-btn>

      <v-btn
        v-if="hasAccess('can_approve_minutes') && !isMeetingMinutesApproved && isMeetingMinutesSubmitted"
        :loading="loading3"
        color="primary"
        small
        @click="approveMinutes"
      >
        <v-icon small>mdi-send-check</v-icon>
        Approve Minutes
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>

    <DocumentViwer
      v-if="OpenfileDialog === true"
      :OpenfileDialog="OpenfileDialog"
      :docValue="docValue"
      :title="`View Attachment`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />

    <VoteResultsDialog
      :endVoteDialog="endVoteDialog"
      :results="results"
      :selectedAgenda="selectedAgenda"
      @CloseVoteResults="endVoteDialog = false"
    />

    <UnanonymousVoteResults
      :isVoteResultsDialog="isVoteResultsDialog"
      :selectedAgenda="selectedAgenda"
      @CloseVoteResults="isVoteResultsDialog = false"
    />

    <MinuteCustomSection
      :OpenCustomMinuteEditor="OpenCustomMinuteEditor"
      :actionTitle="actionTitle"
      :sectionUniqueId="sectionUniqueId"
      :selectedAgenda="selectedAgenda"
      @CloseSectionForm="OpenCustomMinuteEditor = false; loadCustomMinutes()"
    />
  </v-card>
</template>

<script>
import AgendaTopDetails from "../eboard-agendas/AgendaTopDetails.vue";
import AgendaSupportingDocs from "../eboard-agendas/AgendaSupportingDocs.vue";
import MinuteNote from "./MinuteNote.vue";
import NewAction from "../eboard-actions/NewAction.vue";
import NewDecision from "../eboard-decisions/NewDecision.vue";
import VotingAgendaTopDetails from "../eboard-agendas/VotingAgendaTopDetails.vue";
import ConfirmMinutesAgendaTopDetails from "../eboard-agendas/ConfirmMinutesAgendaTopDetails.vue";
import VoteResultsDialog from "./VoteResultsDialog.vue";
import MinuteCustomSection from "./MinuteCustomSection.vue";
import UnanonymousVoteResults from "./UnanonymousVoteResults.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";

export default {
  props: ["takeminutesWindow"],
  components: {
    MinuteCustomSection,
    VotingAgendaTopDetails,
    ConfirmMinutesAgendaTopDetails,
    AgendaTopDetails,
    AgendaSupportingDocs,
    MinuteNote,
    NewAction,
    NewDecision,
    VoteResultsDialog,
    DocumentViwer,
    UnanonymousVoteResults,
  },

  data() {
    return {
      actionTitle: null,
      sectionUniqueId: null,
      OpenCustomMinuteEditor: false,
      isVoteResultsDialog: false,
      endVoteDialog: false,
      voteDetailsDialog: false,
      results: null,
      loadVote: false,
      loading3: false,
      publishAgendas: null,
      MinuteNoteEditor: false,
      ActionEditor: false,
      DecisionEditor: false,
      docValue: "",
      OpenfileDialog: false,
      actionName: "",
      decisionName: "",
      noteTitle: "",
      selectedDirective: {},
      selectedDecision: {},
      showConfirm:true
    };
  },

  watch: {
    selectedAgenda() {
      this.loadCustomMinutes()
    },
    takeminutesWindow() {
      if (this.takeminutesWindow) {
        this.loadCustomMinutes()
      }
    }
  },


  computed: {
    isMeetingMinutesApproved() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails
        .meetingMinutesApproved;
    },

    isMeetingMinutesSubmitted() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails
          .meetingMinutesSubmitted;
    },

    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },

    selectedAgenda() {
      return this.$store.state.AgendasStore.selecteAgenda;
    },

    selectedCustomMinutesSectionsAgenda() {
      return this.$store.state.MinutesStore.agendaItemCustomMinuteSections;
    },

    selectedVoteResults() {
      return this.$store.state.VotesStore.selectedVoteResults;
    },

    selectedAgendaNotes() {
      return this.$store.state.MinutesStore.agendaItemMinuteNote;
    },

    selectedAgendaDecisions() {
      return this.$store.state.MinutesStore.agendaItemMinuteDecision;
    },

    selectedAgendaDirectives() {
      return this.$store.state.MinutesStore.agendaItemMinuteDirective;
    },

    meetingDetails() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails;
    },

    hasNoNotes() {
      return this.isEmptyObject(this.selectedAgendaNotes);
    },
    MinutesCustomSections() {
      return this.$store.state.SettingsStore.boardCustomMinutesSectionsList;
    },
  },

  methods: {
    convertDate(date) {
      return date;
    },

    async loadCustomMinutes() {
      await this.$store.dispatch(
          "loadAllCustomMinuteSections",
          this.selectedAgenda.itemKey
      );
    },

    async approveMinutes() {
      await this.$store.dispatch(
        "approveMeetingMinutes",
        this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
      this.$emit("closeGreatModel");
    },

    async submitMinutes() {
      await this.$store.dispatch(
          "submitMeetingMinutes",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
      await this.$store.dispatch('fetchingMeetingDetails', this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId);
      this.$emit("closeGreatModel");
    },

    async openVoteResultsDialog() {
      await this.$store.dispatch(
        "unanonymousVoteDetails",
        this.selectedAgenda.itemVoteDetails.edges[0].node.voteUniqueId
      );
      this.isVoteResultsDialog = true;
    },

    async ConfirmMeetingMinutes(itemKey) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("confirmMeetingMinutes", itemKey);
      this.showConfirm=false
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },

    async updateVoteResults(vote) {
      await this.$store.dispatch("fetchVoteResultsFromServer", vote);
    },

    async closeVottingSession(vote) {
      await this.$store.dispatch("closeVottingSession", vote);

      if (this.$store.state.VotesStore.voteEndResults.voteOutcome !== "Other") {
        this.results = this.$store.state.VotesStore.voteEndResults;
        this.endVoteDialog = true;
      }
    },

    async openVoteDetails() {
      this.voteDetailsDialog = true;
    },

    isEmptyObject(obj) {
      for (var notesDescriptions in obj) {
        return false;
      }
      return true;
    },

    async loadPreviousMeetingDirective(actionName) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "loadAllDirectiveMinutes",
        this.selectedAgenda.itemKey
      );
      this.actionName = actionName;
      this.ActionEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadPreviousMeetingDecisions(decisionName) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "loadAllDecisionMinutes",
        this.selectedAgenda.itemKey
      );
      this.decisionName = decisionName;
      this.DecisionEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadPreviousMeetingNotes(title) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "loadAllMinuteNotes",
        this.selectedAgenda.itemKey
      );
      this.noteTitle = title;
      this.MinuteNoteEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    editAgendaItemDirective(selectedDirective, actionName) {
      this.selectedDirective = selectedDirective;
      this.actionName = actionName;
      this.ActionEditor = true;
    },

    editAgendaItemDecision(selectedDecision, decisionName) {
      this.selectedDecision = selectedDecision;
      this.decisionName = decisionName;
      this.DecisionEditor = true;
    },

    async deleteAgendaItemDecision(selectedDecision) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteDecisionsMinutes", {
        decisionUniqueId: selectedDecision.decisionUniqueId,
      });
      await this.$store.dispatch(
        "loadAllDecisionMinutes",
        this.selectedAgenda.itemKey
      );
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async deleteAgendaItemDirective(selectedDirective) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteDirectivesMinutes", {
        directiveItemKey: this.selectedAgenda.itemKey,
        directiveUniqueId: selectedDirective.directiveUniqueId,
      });
      await this.$store.dispatch(
        "loadAllDirectiveMinutes",
        this.selectedAgenda.itemKey
      );
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadPreviousCustomMinutesSections(actionLabel, sectionUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "loadAllCustomMinuteSections",
        this.selectedAgenda.itemKey
      );
      this.actionTitle = actionLabel;
      this.sectionUniqueId = sectionUniqueId;
      this.OpenCustomMinuteEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>

<style>
</style>
