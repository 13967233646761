<template>
  <v-dialog v-model="openInterestRegisterWindow" max-width="60%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          Declare Conflict of Interest
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-switch inset color="primary darken-4" v-model="haveNoConflict">
              <template v-slot:label>
                I have no conflict of interest
              </template>
            </v-switch>

            <div class="col-md-12 p-3 rounded agenda-card shadow">
              <div class="font-medium pb-2">
                List of Agenda
              </div>
              <div :class="`border p-2 rounded-md flex mb-2 ${ agenda.selected && !haveNoConflict ? 'active bg-primary/10' : ''} ${!haveNoConflict ? 'cursor-pointer' : ''}`"
                   v-for="(agenda, index) in agendaItemsList" :key="index" @click="selectAgenda(agenda)">
                <label class="cl-checkbox" v-if="!haveNoConflict">
                  <input v-model="agenda.selected" type="checkbox" @click="selectAgenda(agenda)">
                  <span></span>
                </label>
                <div class="font-medium mr-2">
                  {{ index + 1 }}.
                </div>
                <div class="flex">
                  {{ agenda.name }}
                </div>
              </div>

            </div>
            <v-col v-if="(boardSetting()||{}).showConflictedOrganization && !haveNoConflict" cols="12" sm="12" md="12">
              <v-text-field

                v-model="interestOrganisation"
                label="Organisation"
                hint="This field is optional"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="!haveNoConflict">
              <v-text-field
                v-model="interestNature"
                label="Nature of Interest*"
                hint=""
                persistent-hint
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small v-if="!haveNoConflict">*indicates required field</small>
      </v-card-text>


      <div class="flex justify-end text-white p-3">
        <v-btn
            color="red"
            class="text-capitalize mr-2"
            right
            small
            @click="closeNewInterestFormDialog"
        >
          <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
          <span class="text-white">Cancel</span>
        </v-btn>
        <v-btn color="primary"
               class="text-capitalize"
               right
               small
               :disabled="!haveNoConflict && !hasSelectedAgenda"
               :loading="interestLoading"
               @click="registerNewInterest"
        >
          <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
          Save
        </v-btn>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import Icon from "@/views/Icon.vue";

export default {
  components: {Icon},
  props: ["openInterestRegisterWindow", "meetingUniqueId", "agendaItems"],
  data() {
    return {
      interestedAgendaItems: [],
      interestLoading: false,
      interestAgendaItem: null,
      interestOrganisation: " ",
      interestNature: null,
      haveNoConflict: false,
      agendaItemsList: []
    };
  },
  computed: {
    hasSelectedAgenda() {
      let foundAgenda = this.agendaItemsList.filter(a => a.selected)
      return !!foundAgenda.length
    }
  },
  watch: {
    openInterestRegisterWindow() {
      if (this.openInterestRegisterWindow) {
        var temp_agenda_item = [];
        this.agendaItems.forEach((agenda) => {
          agenda.AgendaItems.forEach((item) => {
            temp_agenda_item.push({
              id: item.itemKey,
              selected: false,
              name: item.itemName + " (" + agenda.Agenda + ")",
            });
          });
        });
        this.agendaItemsList = temp_agenda_item
      }
    },
    haveNoConflict() {
      if (this.haveNoConflict) {
        this.agendaItemsList.map(a => {
          return {...a, selected: false}
        })
      }
    }
  },
  methods: {
    selectAgenda(agenda) {
      if (!this.haveNoConflict) this.agendaItemsList = this.agendaItemsList.map(a => {
        if (a.id === agenda.id) {
          a.selected = !a.selected
        }
        return a
      })
    },
    async registerNewInterest() {
      this.$store.dispatch("showLoadingSpinner", true);
      var noticeReceivers = [];
      let interestedAgendaItems = this.agendaItemsList.filter(a => a.selected)

      interestedAgendaItems.forEach((item) => {
        noticeReceivers.push({
          itemUniqueId: item.id,
        });
      });

      var data = {
        declarationMeeting: this.meetingUniqueId,
        declarationOrganization: !this.haveNoConflict ? this.interestOrganisation : null,
        declarationInterest: !this.haveNoConflict ? this.interestNature : null,
        selectedAgendaItems: !this.haveNoConflict ? noticeReceivers : [],
      };
      await this.$store.dispatch("declareAgendaConflictOfInterest", data);

      this.interestOrganisation=" "
      this.interestNature=" "
      this.interestedAgendaItems=[]

      this.$emit("closeNewInterestFormDialog");
      this.$store.dispatch("showLoadingSpinner", false);
    },
    closeNewInterestFormDialog() {
      this.$emit("closeNewInterestFormDialog");
    },
  },
};
</script>

<style scoped>
.agenda-card {
  background-color: rgba(31,58,138,0.05);
}

.active {
  border-left: 3px solid rgba(31,58,138,0.5);
}

.cl-checkbox {
  position: relative;
  display: inline-block;
}

/* Input */
.cl-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.cl-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.cl-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px;
  /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.cl-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.cl-checkbox > input:checked,
.cl-checkbox > input:indeterminate {
  background-color: #1F3A8A;
}

.cl-checkbox > input:checked + span::before,
.cl-checkbox > input:indeterminate + span::before {
  border-color: #1F3A8A;
  background-color: #1F3A8A;
}

.cl-checkbox > input:checked + span::after,
.cl-checkbox > input:indeterminate + span::after {
  border-color: #fff;
}

.cl-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.cl-checkbox:hover > input {
  opacity: 0.04;
}

.cl-checkbox > input:focus {
  opacity: 0.12;
}

.cl-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.cl-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.cl-checkbox > input:active + span::before {
  border-color: #1F3A8A;
}

.cl-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.cl-checkbox > input:disabled {
  opacity: 0;
}

.cl-checkbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.cl-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.cl-checkbox > input:checked:disabled + span::before,
.cl-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}

</style>
